import { ProductsDictionaryTypes } from 'types/i18next'

export enum ProductStatus {
  DRAFT = 'DRAFT',
  ON_VERIFICATION = 'ON_VERIFICATION',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum ProductType {
  NATIVE_APPLICATION = 'NATIVE_APPLICATION',
  NO_CODE_APPLICATION = 'NO_CODE_APPLICATION',
}

enum NativeAppType {
  NATIVE = 'NATIVE',
}

export enum NoCodeAppType {
  EMBEDDED_CODE = 'EMBEDDED_CODE',
  EMBEDDED_LINK = 'EMBEDDED_LINK',
  MIRO_BOARD = 'MIRO_BOARD',
  PAGE_BUILDER = 'PAGE_BUILDER',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  CODE_UPLOAD = 'CODE_UPLOAD',
}

export enum AppType {
  EMBEDDED_CODE = NoCodeAppType.EMBEDDED_CODE,
  EMBEDDED_LINK = NoCodeAppType.EMBEDDED_LINK,
  MIRO_BOARD = NoCodeAppType.MIRO_BOARD,
  PAGE_BUILDER = NoCodeAppType.PAGE_BUILDER,
  EXTERNAL_LINK = NoCodeAppType.EXTERNAL_LINK,
  CODE_UPLOAD = NoCodeAppType.CODE_UPLOAD,
  NATIVE = NativeAppType.NATIVE,
}

export enum ProductCommercialModel {
  CONTRACT = 'CONTRACT',
  FIXED_PRICE = 'FIXED_PRICE',
  FREE = 'FREE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum ProductCategory {
  CREATIVE = 'CREATIVE',
  DATA = 'DATA',
  EXPERIENCE = 'EXPERIENCE',
  MEDIA = 'MEDIA',
}

export enum ProductEditorRole {
  ADMIN = 'ADMIN',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  PRODUCT_OWNER = 'PRODUCT_OWNER',
}

export enum ProductDataContext {
  CLIENT = 'CLIENT',
  BRAND = 'BRAND',
  PROJECT = 'PROJECT',
  MODULE = 'MODULE',
  MARKET = 'MARKET',
  TENANT = 'TENANT',
}

export enum OsMetaRoles {
  VIEWER = 'VIEWER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  OWNER = 'OWNER',
}

export enum ProductOnboardingStatus {
  DRAFT = 'DRAFT',
  OS = 'OS',
  MARKETPLACE = 'MARKETPLACE',
  OS_AND_MARKETPLACE = 'OS_AND_MARKETPLACE',
}

export enum AppVersionType {
  DEVELOPMENT = 'DEVELOPMENT',
  DEMO = 'DEMO',
  PRODUCTION = 'PRODUCTION',
}

export enum AppConfigSettingsTabs {
  GENERAL = 'GENERAL',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  AVAILABILITY = 'AVAILABILITY',
  DATA_TRANSFER = 'DATA_TRANSFER',
}

export enum AppConfigTabs {
  VERSIONS = 'versions',
  PROFILES = 'profiles',
}

export const AppVersionTypeLabel = {
  [AppVersionType.DEVELOPMENT]: 'app_version_types.labels.development',
  [AppVersionType.DEMO]: 'app_version_types.labels.demo',
  [AppVersionType.PRODUCTION]: 'app_version_types.labels.production',
} as const

export const AppVersionTypeMessage = {
  [AppVersionType.DEVELOPMENT]: 'app_version_types.messages.development',
  [AppVersionType.DEMO]: 'app_version_types.messages.demo',
  [AppVersionType.PRODUCTION]: 'app_version_types.messages.production',
} as const

export const ProductTypeLabel: Record<string, keyof ProductsDictionaryTypes['product_type_names']> = {
  [ProductType.NATIVE_APPLICATION]: 'native',
  [NoCodeAppType.EMBEDDED_CODE]: 'embedded_code',
  [NoCodeAppType.EMBEDDED_LINK]: 'embedded_link',
  [NoCodeAppType.MIRO_BOARD]: 'miro_board',
  [NoCodeAppType.PAGE_BUILDER]: 'page_builder',
  [NoCodeAppType.EXTERNAL_LINK]: 'external_link',
  [NoCodeAppType.CODE_UPLOAD]: 'code_upload',
} as const

export enum BenefitIcon {
  SYNC = 'WppIconSync',
  SEARCH = 'WppIconSearch',
  CAMPAIGN = 'WppIconCampaign',
  TARGET = 'WppIconTarget',
  PITCH = 'WppIconPitch',
  EXTENSION = 'WppIconExtension',
  BAR_CHART = 'WppIconBarChart',
  DATA_CLOUD_ON = 'WppIconDataCloudOn',
  MONEY = 'WppIconMoney',
  SUPPORT_CHAT = 'WppIconSupportChat',
  SHAPES = 'WppIconShapes',
  DESIGN = 'WppIconDesign',
}

export enum UserProductRequestType {
  ASSISTANCE = 'ASSISTANCE',
}

export enum AppLibraryType {
  Window = 'WINDOW',
  SystemJS = 'SYSTEM_JS',
  ESM = 'ESM',
}

export const AppLibraryTypeLabel = {
  [AppLibraryType.SystemJS]: 'general.library_type.options.systemjs',
  [AppLibraryType.ESM]: 'general.library_type.options.esm',
  [AppLibraryType.Window]: 'general.library_type.options.window',
} as const
