import { styled } from '@mui/material/styles'
import { WppGrid, WppTopbar, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const TopBarWrapper = styled(Flex)`
  position: sticky;
  top: var(--wpp-os-header-height);
  min-height: var(--wpp-devhub-header-height);
  background-color: var(--wpp-white-color);
  border-bottom: 1px solid var(--wpp-divider-bg-color, var(--wpp-grey-color-300));
  z-index: 3;
`

export const Wrapper = styled('div')`
  width: 100%;
  max-width: var(--wpp-os-content-max-width, auto);
  margin: 0 auto;
`

export const GridItem = styled(WppGrid)`
  padding-top: 0;
  padding-bottom: 0;
`

export const TopBar = styled(WppTopbar)`
  &::part(body) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const AppName = styled(WppTypography)`
  margin-right: 32px;
  user-select: none;
`
