import { styled } from '@mui/material/styles'

export const CustomSvg = styled('svg')`
  .fillPrimary100 {
    fill: var(--wpp-primary-color-100, #eee8ff);
  }

  .fillPrimary200 {
    fill: var(--wpp-primary-color-200, #e2d9ff);
  }

  .fillPrimary300 {
    fill: var(--wpp-primary-color-300, #cab8ff);
  }

  .fillPrimary400 {
    fill: var(--wpp-primary-color-400, #8508e8);
  }

  .fillPrimary500 {
    fill: var(--wpp-primary-color-500, #5e00b5);
  }

  .fillWhite {
    fill: var(--wpp-white-color, #ffffff);
  }

  .fillDanger300 {
    fill: var(--wpp-danger-color-300, #ffafa3);
  }

  .fillDanger400 {
    fill: var(--wpp-danger-color-400, #e81c23);
  }

  .fillWarning400 {
    fill: var(--wpp-warning-color-400, #f15619);
  }

  .fillGrey600 {
    fill: var(--wpp-grey-color-600, #8b919a);
  }

  .strokePrimary100 {
    stroke: var(--wpp-primary-color-100, #eee8ff);
  }

  .strokePrimary200 {
    stroke: var(--wpp-primary-color-200, #e2d9ff);
  }

  .strokePrimary300 {
    stroke: var(--wpp-primary-color-300, #cab8ff);
  }

  .strokePrimary400 {
    stroke: var(--wpp-primary-color-400, #8508e8);
  }

  .strokeWhite {
    stroke: var(--wpp-white-color, #ffffff);
  }

  .stopColorWhite {
    stop-color: var(--wpp-white-color, #ffffff);
  }

  .stopColorPrimary100 {
    stop-color: var(--wpp-primary-color-100, #eee8ff);
  }

  .stopColorPrimary300 {
    stop-color: var(--wpp-primary-color-300, #cab8ff);
  }

  .stopColorPrimary400 {
    stop-color: var(--wpp-primary-color-400, #8508e8);
  }

  .stopColorPrimary500 {
    stop-color: var(--wpp-primary-color-500, #5e00b5);
  }

  .fillGrey600 {
    fill: var(--wpp-grey-color-600, #8b919a);
  }
`
