import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { PropsWithChildren, StrictMode } from 'react'

import { GlobalErrorBoundary } from 'ui-shared/errorBoundaries/global/GlobalErrorBoundary'
import { ImportErrorBoundary } from 'ui-shared/errorBoundaries/import/ImportErrorBoundary'

export const Root = ({ children, ...rest }: PropsWithChildren<MicroAppCustomProps>) => (
  <StrictMode>
    <GlobalErrorBoundary>
      <ImportErrorBoundary>
        <OsProvider {...rest}>{children}</OsProvider>
      </ImportErrorBoundary>
    </GlobalErrorBoundary>
  </StrictMode>
)
