export enum TipId {
  PRODUCT_FORM_MP_HINT = 'product_form_mp_hint',
  PRODUCT_ONBOARDING_VERSION_LIST = 'product_onboarding_version_list',
  PRODUCT_ONBOARDING_PROFILES_LIST = 'product_onboarding_profiles_list',
  PRODUCT_ONBOARDING_ROLES = 'product_onboarding_roles',
  PRODUCT_ONBOARDING_PERMISSIONS = 'product_onboarding_permissions',
  PRODUCT_ONBOARDING_DATA_TRANSFER = 'product_onboarding_data_transfer',
  PRODUCT_ONBOARDING_AVAILABILITY = 'product_onboarding_availability',
}

export enum TipState {
  OPENED = 'opened',
  CLOSED = 'closed',
}

export const tipIdValues = Object.values(TipId)
