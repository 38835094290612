const isProduction = process.env.NODE_ENV !== 'development'

const prodEnvironment = {
  // GA_MEASUREMENT_ID: 'WPP_TEMPLATE_GA_MEASUREMENT_ID',
  DEVHUB_FE_ASSETS: 'https://devhub-fe-ch-pitch.wppopen.com/',
}
const devEnvironment = {
  // GA_MEASUREMENT_ID: 'G-70299WP13K',
  DEVHUB_FE_ASSETS: 'http://localhost:8600/',
}

export const devhubPublicUrl = isProduction
  ? process.env.REACT_APP_WPP_DEVHUB_FE_ASSETS || prodEnvironment.DEVHUB_FE_ASSETS
  : devEnvironment.DEVHUB_FE_ASSETS

export enum API_TYPES {
  WPP_DEVHUB_API = 'WPP_DEVHUB_API',
  WPP_MASTER_DATA_API = 'WPP_MASTER_DATA_API',
  WPP_PAGE_BUILDER_API = 'WPP_PAGE_BUILDER_API',
  WPP_STATIC_ASSETS_PROXY_API = 'WPP_STATIC_ASSETS_PROXY_API',
}

const developmentApi: Record<API_TYPES, string> = {
  WPP_DEVHUB_API: 'https://devhub-api-ch-hulk.os-dev.io/devhub/api',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
  WPP_PAGE_BUILDER_API: 'https://page-builder-api-ch-hulk.os-dev.io/page-builder/api',
  WPP_STATIC_ASSETS_PROXY_API: 'https://wpp-asset-proxy-api-ch-hulk.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  WPP_DEVHUB_API: 'https://devhub-api-ch-pitch.wppopen.com/devhub/api',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-pitch.wppopen.com/api',
  WPP_PAGE_BUILDER_API: 'https://page-builder-api-ch-pitch.wppopen.com/page-builder/api',
  WPP_STATIC_ASSETS_PROXY_API: 'https://wpp-asset-proxy-api-ch-pitch.wppopen.com/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env[`REACT_APP_${type}`] ||
    (isProduction ? productionApi[type as API_TYPES] : developmentApi[type as API_TYPES])
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
