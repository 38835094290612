export enum FEATURE_TYPES {
  WPP_REQUEST_ASSISTANCE = 'WPP_REQUEST_ASSISTANCE',
  WPP_DUPLICATE_VERSION = 'WPP_DUPLICATE_VERSION',
}

type FEATURE_TYPES_NAMES = keyof typeof FEATURE_TYPES extends `WPP_${infer P}` ? P : never
export const featureFlags: Record<FEATURE_TYPES, `WPP_TEMPLATE_${FEATURE_TYPES_NAMES}`> = {
  WPP_REQUEST_ASSISTANCE: 'true',
  WPP_DUPLICATE_VERSION: 'true',
} as const

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flags =
    process.env.NODE_ENV !== 'development' ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flags }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
